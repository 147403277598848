<template>
  <v-container>
    <v-btn text @click="$router.go(-1)" ><v-icon
        left
        dark
      >
        mdi-backburger
      </v-icon> Zurück zu Referenzen</v-btn>
    <ImageGallery v-model="galleryData" />
  </v-container>
</template>
<script>
import ImageGallery from '@/components/ImageGallery.vue';

export default {
  name: 'eintracht-kraftraum',
  components: {
    ImageGallery,
  },
  data: () => ({
    galleryData: {
      imagePath:
        'https://medo-sanierung.de/public_files/img/references/eintracht-kraftraum',
      imageCount: 30,
      imageFileFormat: '.jpg',
      name: 'Neuer Kraftraum bei dem Eintrachter Tennisverein',
      description:
        'Aus einem ungenutzen Raum im 1. OG der Tennishalle wurde auf zwei Etagen ein stilvoller und moderner Kraftraum für die Mitglieder des Eintrachter Tennisvereins. Der Zugang zu dem Kraftraum wurde durch den Bau einer Außenbetontreppe ermöglicht. Neben der Demontage und Abbrucharbeiten waren hauptsächlich Dämm- und Isolierarbeiten, Trockenbau einschließlich Brandschutz und Malerarbeiten notwendig. Mit dem Fließestrich wurde ein begehbarer Fußboden gemacht, der für den speziellen Sportler-Gummibelag einen optimalen Untergrund liefert.',
    },
    //
  }),
};
</script>
